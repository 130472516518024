@import 'styles/mixins';

.navBar {
  background-color: var(--color-white);
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px var(--color-cloud);
  padding: 1.2rem 2rem;
  column-gap: 0.5rem;
  height: 5rem;

  @include mobile {
    display: none;
  }

  .headerLogo {
    @include mobile {
      &.searchBarOpen {
        display: none;
      }
    }
  }

  .middleSection {
    flex: 1;
  }

  .cta {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    width: unset;

    @include mobile {
      &.searchBarOpen {
        display: none;
      }
    }
  }

  .userSettings {
    @include mobile {
      &.searchBarOpen {
        display: none;
      }
    }
  }

  .navBarButtonPay {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: var(--color-forest);
    color: var(--color-white);
  }
}
