.bankButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: var(--color-black);

  .innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      margin-right: 0.3rem;
      width: 1.2rem;
      height: 1.2em;
      fill: var(--color-white);
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.pressable {
    cursor: pointer;
  }
}
