.container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .innerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    max-width: 25rem;
  }
}