@import "styles/mixins";

.drawer {
  display: flex;
  row-gap: 1rem;
  padding: 2rem 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-cloud);
  height: calc(100vh - 5rem);

  @include mobile {
    bottom: 0;
    z-index: 2;
    width: 100%;
    position: fixed;
    flex-direction: row;
    padding: 0.5rem 2rem;
    filter: drop-shadow(0 5px 7px rgba(0 0 0 / 20%));
    height: unset;
    top: unset;
  }

  .toggle {
    @include mobile {
      display: none;
    }
  }

  .navigation {
    display: flex;
    row-gap: .5rem;
    flex-direction: column;

    @include mobile {
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .bottom {
    @include mobile {
      display: none;
    }
  }
}



