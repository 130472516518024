@import "styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  z-index: 3;
  height: 100%;
  border-radius: 2rem;
  background-color: var(--color-fog);
  padding: 0.6rem;
  width: 100%;

  .searchIcon {
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    path {
      fill: var(--color-concrete);
    }
  }

  .searchBar {
    border: unset;
    color: var(--color-iron);
    background-color: transparent;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--color-concrete);
    }
  }
}
