.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .settlement {
    margin-bottom: 1.5rem;
    font-weight: 400;
    font-size: 0.8rem;
    color: var(--color-iron)
  }

  .nextButton {
    font-size: 1rem;
    padding: 1rem 0;
  }
}
