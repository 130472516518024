.typography {
  font-family: var(--font-inter), var(--font-literata), Arial, Helvetica, sans-serif, serif;
  margin: 0;
  padding: 0;
  color: inherit;
  letter-spacing: 0;
  line-height: normal;
}

.variant-subtext {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.variant-body {
  font-size: 0.8rem;
  line-height: 1.25rem;
}

.variant-bodyTitle {
  font-size: 1rem;
  line-height: 1.1rem;
}

.variant-headline {
  font-size: 1.25rem;
  line-height: 1.3rem;
}

.variant-h4 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.variant-h3 {
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: 800;
}

.variant-h2 {
  font-size: 3rem;
  line-height: 3rem;
}

.variant-h1 {
  font-size: 4.5rem;
  line-height: 5.4rem;
}
