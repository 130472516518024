@import "styles/mixins";

.container {
  display: flex;
  align-items: center;

  @include mobile {
    justify-content: center;
  }

  .item {
    margin-right: 1rem;

    svg {
      fill: var(--color-cloud)
    }
  }
}