.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 4rem;
    z-index: 1;
  }
}