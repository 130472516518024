.bottomSection {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;

  .rightBottomSection {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}

.square {
  aspect-ratio: 1 / 1;
  border-radius: 1rem;
  overflow: hidden;
}
