:root {
  // Grey scale
  --color-black: #000;
  --color-raven: #2c2c2e;
  --color-iron: #515151;
  --color-concrete: #9f9f9f;
  --color-cloud: #dadada;
  --color-fog: #f0efef;
  --color-pearl: #f8f8f8;
  --color-white: #fff;


  // Green scale
  --color-forest: #034f4d;
  --color-forest-magic-link: #044e4d;
  --color-basil: #00a688;
  --color-aqua: #04eba6;
  --color-sage: #c0f1e6;
  --color-mint: #e9fffa;
  --color-dino: #8ff784;
  --color-lime: #d6f6a3;
  --color-pear: #ddec97;
  --color-seaweed: #B3CACA;
  --color-mint-pale: #f4fffc;

  // Yellow scale
  --color-honey: #cd9400;
  --color-gold: #f4b102;
  --color-sun: #FFD772;
  --color-banana: #fff4d1;
  --color-cotton: #f9f7f1;
  --color-golg: #f4b102;

  // Orange scale
  --color-rust: #bc2d00;
  --color-tiger: #f26523;
  --color-orange: #ffa77e;
  --color-peach: #ffccb5;
  --color-shell: #fff7f3;

  // Magenta scale
  --color-jam: #750054;
  --color-gum: #f34b9a;
  --color-tulip: #ff96c8;
  --color-rose: #ffbfdd;
  --color-blush: #fff8fb;

  // Blue scale:
  --color-night: #100075;
  --color-denim: #005dcb;
  --color-pool: #62b4f6;
  --color-sky: #a1d8ff;
  --color-ice: #f4f8ff;

  //others
  --color-black-70-percent: rgb(0 0 0 / 70%);
  --color-black-50-percent: rgb(0 0 0 / 50%);
  --color-black-17-percent: rgb(0 0 0 / 17%);
  --color-black-8-percent: rgb(0 0 0 / 8%);
  --color-fog-94-percent: rgb(240 239 239 / 94%);
}

[data-theme="dark"] {
  // Grey scale
  --color-black: #fff;
  --color-raven: #f8f8f8;
  --color-iron: #dadada;
  --color-concrete: #515151;
  --color-cloud: #2c2c2e;
  --color-fog: #1f1f1f;
  --color-pearl: #141414;
  --color-white: #121212;

  // Green scale
  --color-forest: #04EBA6;
  --color-forest-magic-link: #04EBA6;
  --color-basil: #8df4ee;
  --color-aqua: #20c9b4;
  --color-sage: #2e4744;
  --color-mint: #264a47;
  --color-dino: #149a34;
  --color-lime: #2d4f2c;
  --color-pear: #222f2a;
  --color-seaweed: #3e3f3f;
  --color-mint-pale: #3a3f3c;

  // Yellow scale
  --color-honey: #f5c277;
  --color-gold: #c48c0f;
  --color-sun: #2a1f24;
  --color-banana: #5c564b;
  --color-cotton: #2C2C2E;

  // Orange scale
  --color-rust: #ff5a00;
  --color-tiger: #f9a840;
  --color-orange: #e3b899;
  --color-peach: #c78e6c;
  --color-shell: #b06d3b;

  // Magenta scale
  --color-jam: #ff1a8c;
  --color-gum: #cc1d79;
  --color-tulip: #c75496;
  --color-rose: #b584b6;
  --color-blush: #a2aab0;

  // Blue scale:
  --color-night: #b3c3f3;
  --color-denim: #88b7e0;
  --color-pool: #5691c8;
  --color-sky: #2e5f9e;
  --color-ice: #172b4d;

  // other
  --color-fog-94-percent: rgb(31 31 31 / 94%);
}
