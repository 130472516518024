.chip {
  display: flex;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0.5rem 1rem;
  border-radius: 25rem;
  color: var(--color-iron);
  border: 1px solid var(--color-cloud);

  .icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.375rem;

    path {
      fill: var(--color-iron);
    }
  }

  &.selected {
    color: var(--color-black);
    border: 1px solid var(--color-black);

    .icon {
      path {
        fill: var(--color-black);
      }
    }
  }
}
