@import "styles/mixins";

.modal {
  background-color: var(--color-cotton);
  padding: 2rem;
  border-radius: 1rem;
  width: 35rem;

  .title {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .subtitle {
    color: var(--color-iron);
    margin-bottom: 0.5rem;
  }

  .button {
    max-width: unset;
    margin-top: 2rem;
  }

  @include mobile {
    width: 90% !important;
    height: unset !important;
  }
}