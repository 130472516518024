.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: .5rem;
  }

  .subtitle {
    color: var(--color-iron)
  }
}