.container {
  display: flex;

  .title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: flex-end;

    &:last-child {
      margin-bottom: 0;
    }

    .left {
      display: flex;
      flex: 1;
    }

    .right {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .bottomSection {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    .errorMessage {
      color: var(--color-rust);
      text-align: center;
    }

    .subtitle {
      color: var(--color-iron);
      flex: 1;
      text-align: center;
      margin-bottom: 1rem;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      .nextButton {
        max-width: unset;
        width: 80%;
      }
    }
  }
}