@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .iconContainer {
    padding: 3rem;
    display: flex;
    justify-content: flex-end;

    @include mobile {
      padding: 2rem;
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--color-iron);
      cursor: pointer;

      path {
        stroke: var(--color-iron);
        stroke-width: 0.03rem;
      }
    }
  }

  .contentContainer {
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: column;
      width: 40rem;
      max-width: 100%;

      @include mobile {
        height: 100%;
        width: 90%;
      }
    }
  }
}