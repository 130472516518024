.container {
  box-shadow: 0 5px 10px -6px rgba(81 81 81 / 50%);
  transform: translateY(calc(100% + 0.5rem));
  background-color: var(--color-white);
  border-radius: 0.75rem;
  position: absolute;
  padding: 1.5rem 0 0;
  bottom: 0;
  right: 0;
  width: 300px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;

    .avatar {
      background-color: var(--color-black);
      width: 96px;
      height: 96px;
      border-radius: 50%;
      color: var(--color-white);
      font-size: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      font-weight: 700;
    }

    .fullName {
      font-size: 16px !important;
      margin: 20px 0 10px;
      color: var(--color-black);
      line-height: 1.3 !important;
      text-align: center;
      padding: 0 20px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .address {
      font-size: 13px;
      color: var(--color-iron);
    }

    .email {
      font-size: 13px;
      color: var(--color-iron);
    }
  }

  .menuBody {
    margin: 15px 0 0;
    padding: 5px 0;

    .menuButton {
      align-items: center;
      user-select: none;
      display: flex;
      border-top: 1px solid var(--color-fog);
      margin: 5px 0;
      padding: 13px 25px;
      font-size: 15px;
      font-weight: 500;
      border-radius: 0;
      text-align: left;
      color: var(--color-black);

      &:first-child {
        padding-top: 24px;
      }

      & + .menuButton {
        border-top: 0;

        & + .menuButton {
          border-top: 1px solid var(--color-fog);
          padding: 24px 25px 10px;
          margin-top: 15px;
        }

        &:hover,
        &.active {
          color: var(--color-iron);
        }
      }

      .menuButtonIcon {
        width: 20px;
        fill: var(--color-black);
        margin-right: 10px;
      }
    }
  }
}
