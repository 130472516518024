@import "styles/mixins";

.container {
  display: flex;
  align-items: center;

  @include mobile {
    margin-bottom: 2rem;
    justify-content: center;
  }

  .appstore {
    margin-right: 1rem;
  }

  svg {
    fill: var(--color-cloud);
  }
}