@import "styles/mixins";

.container {
  flex: 1;
  justify-content: flex-end;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: row;
    padding-left: 1rem;
  }

  .gridItem {
    font-size: 0.9rem;
    color: var(--color-white);
    font-weight: 600;
    margin-bottom: 2rem;

    @include mobile {
      margin-bottom: 1rem;
    }
  }
}