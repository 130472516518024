@import "styles/mixins";

.container {
  .logoContainer {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    .signLogo {
      width: 1.4rem;
      height: 1.4rem;
      fill: var(--color-white);
      margin-right: 1rem;
    }

    .textLogo {
      width: 4.5rem;
      height: 4.5rem;
      fill: var(--color-white);
    }
  }

  .linksContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    .scan {
      @media (max-width: 900px) {
        display: none;
      }
    }
  }

  .socialContainer {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }
  }
}