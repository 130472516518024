
.container {
  position: fixed;
  border-radius: 1rem;
  min-width: 100vw;
  height: 100vh;
  background-color: var(--color-cotton);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
}
