.container {
  background-color: var(--color-fog-94-percent);
  flex-direction: column;
  margin-top: 0.3rem;
  border-radius: 1rem;
  position: absolute;
  height: 18rem;
  width: 100%;
  top: 100%;
  left: 0;
  overflow: hidden;
  z-index: 5;
}
