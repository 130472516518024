.container {
  color: var(--color-cloud);
  font-size: 0.7rem;

  .paragraph {
    margin-bottom: 1rem;
    font-weight: 300;
  }

  .link {
    text-decoration: underline;
    font-weight: 700;
  }
}