.input {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  border: 1px solid var(--color-white);
  outline: none;
  caret-color: var(--color-raven);
  font-weight: 600;
  transition: 0.25s border ease-in-out;
  background-color: var(--color-white);

  &:focus {
    border: 1px solid var(--color-concrete);
  }

  &.noBorder {
    border: none;
  }

  &::selection {
    background: var(--color-aqua);
    color: var(--color-black);
  }
}

.placeholder {
  margin-left: 1rem;
  font-size: 1.2rem;
  color: var(--color-concrete);
  font-weight: 600;
}

.errorMessage {
  margin-top: 0.4rem;
  color: var(--color-rust);
  font-size: 0.75rem;
  height: 0.75rem;
  visibility: hidden;

  &.show {
    visibility: visible;
  }
}
