.modalContainer {
  display: flex;
  flex-direction: column;
  width: 40rem;
  min-width: fit-content;
  background-color: var(--color-white);

  .topContainer {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr auto 1fr;
    padding: 1.5rem 0;
    border-bottom: var(--color-cloud) 2px solid;
  }

  .closeIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;

    svg {
      fill: var(--color-raven);
    }
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    color: var(--color-raven);
  }

  .childrenContainer {
    display: flex;
    width: 100%;
    padding: 20px 0;
    align-items: center;
    justify-content: space-around;
  }
}

.connectBankButton {
  height: 60px;
  width: 40%;
  background-color: var(--color-forest);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 0.5rem;
  cursor: pointer;
}

.disabledButton {
  opacity: 0.4;
  cursor: auto;
}

.errorMessage {
  margin: 15px;
  color: #bc2d00;
}
