@import './palette';
@import './sizings';
@import './mixins';

:root {
  --drop-shadow: drop-shadow(0 5px 10px var(--color-concrete));
  --doc-height: 100%;
}

html,
body {
  scrollbar-gutter: stable;
  padding: 0;
  margin: 0;
  @include mobile {
    -webkit-tap-highlight-color: transparent;
  }

  background-color: var(--color-white);
  color: var(--color-black);
}

input,
select:focus,
textarea {
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: var(--font-inter), var(--font-literata), Arial, Helvetica,
    sans-serif, serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
link {
  padding: 0;
  margin: 0;
}

//disable numeric arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// make autofill on ios without yellow background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #fff inset !important;
  background-color: #fff !important;
  background-clip: content-box !important;
}

.toast {
  padding: 0.25rem !important;
  min-height: 2rem !important;
  border-radius: 0.25rem !important;
}

ul {
  padding: 0;
  margin: 0;
}

.react-tel-input input::placeholder {
  color: var(--color-concrete);
}
