
.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .fast {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin-bottom: .5rem;
    align-items: center;
    padding: 1rem 0.2rem;
    max-width: unset;

    .fastMiddle {
      grid-column-start: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        stroke: var(--color-white);
        stroke-width: 2;
        width: 1.1rem;
        height: 1.1rem;
        margin-right: 0.5rem;
      }
    }

    .fastRight {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-seaweed);
      font-weight: 400;
    }
  }

  .regular {
    max-width: unset;
    border: 1px solid var(--color-cloud);

    .standard {
      font-weight: 600;
    }

    .day {
      font-weight: 400;
    }
  }
}