.container {
  flex: 1;
  display: flex;
  align-items: flex-start;

  .text {
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--color-dino);
    margin-right: 2rem;
    max-width: 12rem;
  }

  .qr {
    overflow: hidden;
    border-radius: 0.3rem;

    #react-qrcode-logo {
      display: none;
    }
  }
}