.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  border: 0;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--color-black);
  }
}

.direction-left {
  transform: rotate(0deg);
}

.direction-right {
  transform: rotate(180deg);
}

.direction-top {
  transform: rotate(90deg);
}

.direction-bottom {
  transform: rotate(-90deg);
}
