@import 'styles/mixins';

.modal_container {
  position: fixed;
  border-radius: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 700px;
  height: auto;
  padding-bottom: 3rem;
  background-color: var(--color-cotton);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  @include mobile {
    padding: 0 1rem;
  }

  .iconContainer {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;

    @include mobile {
      padding: 2rem 1rem;
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--color-iron);
      cursor: pointer;

      path {
        stroke: var(--color-iron);
        stroke-width: 0.03rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 40rem;
    max-width: 100%;

    @include mobile {
      height: 100%;
      width: 100%;
    }

    .title {
      font-size: 1.4rem;
      font-weight: 600;
      padding-bottom: 0.8rem;
    }

    .row {
      width: 100%;
      margin: 1rem 0;
    }

    .errorText {
      color: var(--color-rust);
      text-align: center;
      font-size: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
}
