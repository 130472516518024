.drawerToggleButton {
  z-index: 1;
  padding: 2px;
  position: absolute;
  right: 0;
  width: unset;
  line-height: 0;
  border-radius: 25rem;
  background: var(--color-white);
  border: 1px solid var(--color-cloud);
  transform: translate(50%, -50%) !important;

  .icon {
    width: 1rem;
    height: 1rem;
    transform: rotate(180deg);
    fill: var(--color-concrete);
    transition: transform 0.25s;

    &.expand {
      transform: unset;
    }
  }
}
