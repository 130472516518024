.container {
  box-shadow: 0 5px 10px -6px rgb(81 81 81 / 50%);
  background-color: var(--color-white);
  border-radius: 0.75rem;
  position: absolute;
  z-index: 1000;
  width: 200px;
  top: 84%;
  bottom: auto;

  .subMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  .subMenuItem {
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    color: var(--color-black);

    &:hover {
      background: var(--color-pearl);
    }
  }
}
