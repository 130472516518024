.container {
  display: flex;

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  .rowsContainer {
    overflow: auto;
    max-height: 15rem;
    margin-bottom: 2rem;

    .row {
      padding-right: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      .leftSection {
        display: flex;
        align-items: center;

        .address {
          font-weight: 600;
        }

        .image {
          margin-right: 0.5rem;
        }
      }

      .money {
        font-weight: 600;
      }
    }
  }

  .buttonsContainer {
    .withdraw {
      margin-bottom: 0.4rem;
      max-width: unset;
    }

    .cancel {
      max-width: unset;
      background-color: var(--color-cotton);
      color: var(--color-iron);
      border: 1px solid var(--color-concrete);
    }
  }
}