@import 'styles/mixins';

.container {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px 0 50px;

  @media (max-width: 790px) {
    padding: 0;
    gap: 0;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    gap: 6px;
    padding: 10px 16px;
    white-space: nowrap;
    color: var(--color-black);
    transition: 0.2s ease-in;

    &:hover {
      color: var(--color-iron);
    }

    @include mobile {
      padding: 5px;
      gap: 3px;
      font-size: 18px;
    }
  }

  .arrow {
    width: 0.75rem;
    height: 0.75rem;
    transform: rotate(-90deg);
    fill: var(--color-black);
  }
}
