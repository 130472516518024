.container {
  align-items: center;
  display: flex;
  width: 100%;

  .image {
    object-fit: cover;
    border-radius: 25rem;
    margin-right: 0.75rem;
  }

  .detailsContainer {
    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .subtitle {
      margin-top: 0.25rem;
      font-size: 0.825rem;
      color: var(--color-iron);
    }
  }
}
