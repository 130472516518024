.formGroup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .formLabel {
    position: absolute;
    left: 0;
    z-index: 1;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
    pointer-events: none;

    &.focused {
      transform: translateY(calc(-20px - 1em));
      font-size: 0.8em;
      pointer-events: none;

      &.hide {
        display: none;
      }
    }
  }

  .focused .formLabel {
    display: none;
  }

  .formInput {
    padding: 10px;
    width: 100%;
    caret-color: var(--color-black);
    color: var(--color-iron);
    background-color: var(--color-white);
  }

  .inputStyle {
    color: black;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
  }
}
