@import "styles/mixins";

.drawerItem {
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 25rem;
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  transition: background-color 0.25s;

  .leftSection {
    display: flex;
    align-items: center;
    max-height: 1.5rem;
    padding: 1rem 0;

    .label {
      color: var(--color-black);
      transition: margin-left 0.25s, width 0.25s;
      text-align: left;
      font-weight: 500;
      overflow: hidden;
      font-size: 1rem;
      width: 0;

      &.open {
        width: 6.25rem;
        margin-left: 0.5rem;
      }

      @include mobile {
        &.open {
          width: 0;
          margin-left: unset;
        }
      }
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      padding: 0;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      & > path {
        fill: var(--color-black);
      }
    }
  }

  &:hover {
    background: var(--color-pearl);
  }

  &.active {
    background: var(--color-mint-pale);

    .leftSection {
      .label {
        font-weight: 700;
        color: var(--color-forest);
      }

      .icon > path {
        fill: var(--color-forest);
      }
    }
  }

  &:disabled {
    background-color: transparent;

    &:hover {
      background: unset;
    }

    .leftSection {
      .label {
        color: var(--color-fog);
      }

      .icon > path {
        fill: var(--color-fog);
      }
    }
  }
}
