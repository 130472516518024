.container {
  background-color: var(--color-cotton);
  flex: 1;


  .topSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    .circle {
      margin-bottom: 0.5rem;
    }
  }

  .money {
    text-align: center;
    font-weight: 700;
    font-size: 3.3rem;
    margin: 1rem 0;
  }

  .bottomContainer {
    width: 100%;
  }
}
