.transparentBackdrop {
  background-color: transparent;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.container {
  position: relative;

  .toggleContainer {
    display: flex;
    cursor: pointer;
    column-gap: 0.5rem;
    align-items: center;

    .firstLetterLabel {
      background-color: var(--color-black);
      color: var(--color-white);
      border-radius: 25rem;
      font-size: 0.75rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.38rem;
      width: 2.38rem;
    }

    .arrow {
      width: 0.75rem;
      height: 0.75rem;
      transform: rotate(-90deg);
      fill: var(--color-black);
    }
  }
}
