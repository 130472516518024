.outerContainer {
  position: absolute;
  background-color: #177761;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .ball {
    z-index: 1;
    position: absolute;
    width: 45rem;
    height: 45rem;
    border-radius: 100%;
    background: var(--accent);
    background: radial-gradient(circle closest-side, #bdee7e 1%, transparent);
    background-repeat: no-repeat;
  }

  .ball2 {
    z-index: 1;
    position: absolute;
    width: 35rem;
    height: 45rem;
    border-radius: 100%;
    background: var(--accent);
    background: radial-gradient(circle closest-side, #8ddf7e 1%, transparent);
    background-repeat: no-repeat;
  }
}
