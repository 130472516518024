.container {
  display: flex;
  gap: .5rem;

  .chip {
    flex: 1;
    text-align: center;
    background-color: var(--color-white);
    border: 0;
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
    font-weight: 400;

    &.active {
      background-color: var(--color-mint);
      color: var(--color-forest);
      border: 1px solid var(--color-forest);
    }
  }

  .textContainer {
    flex: 1;
    text-align: center;
  }
}
