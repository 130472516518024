.container {
  .title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .table {
    margin: 1.5rem 0;
  }
}

.loader {
  flex: 1;
  justify-content: center;
  align-items: center;
}