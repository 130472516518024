@import "styles/mixins";

.container {
  display: flex;
  align-items: center;

  .logoContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    .textLogo {
      width: 5rem;
      margin-left: 1rem;
      fill: var(--color-black);

      @include mobile {
        display: none;
      }
    }

    .logoIcon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--color-black);
    }
  }
}
