@import "styles/mixins";

.container {
  background-color: var(--color-forest);
  display: flex;
  justify-content: center;
  padding: 3rem 0;

  &.dark {
    background-color: var(--color-black);
  }

  @include mobile {
    padding-bottom: 5rem;
  }

  .contentContainer {
    width: 90%;
    max-width: 85rem;

    .topSection {
      padding-bottom: 2.3rem;
      border-bottom: 1px solid var(--color-cloud);
    }

    .bottomSection {
      padding-top: 2.3rem;
    }
  }
}