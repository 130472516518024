@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .iconContainer {
    padding: 2rem 0;
    display: flex;
    justify-content: flex-end;

    @include mobile {
      padding: 2rem;
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--color-iron);
      cursor: pointer;

      path {
        stroke: var(--color-iron);
        stroke-width: 0.03rem;
      }
    }
  }

  .contentContainer {
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: column;
      width: 40rem;
      max-width: 100%;

      @include mobile {
        height: 100%;
        width: 90%;
      }

      .title {
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 0.8rem;
      }

      .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 0.5rem;

        .right {
          display: flex;
          flex-direction: column;
          flex: 2;
        }

        .left {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }

      .errorMessage {
        color: var(--color-rust);
      }

      .button {
        margin-top: 2rem;
      }
    }
  }
}
