.button {
  display: inline-block;
  padding: 1rem 2rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;

  &:hover {
    opacity: 0.9;
  }
}

.variant-primary {
  background-color: var(--color-forest);
  color: var(--color-white);

  &.disabled {
    opacity: 0.4;
  }
}

.variant-secondary {
  background-color: var(--color-cotton);
  color: var(--color-iron);

  &.disabled {
    outline: 1px solid var(--color-iron);
    cursor: not-allowed;
  }
}

.variant-text {
  background-color: transparent;
  color: var(--color-iron);
}

.variant-accent {
  background-color: var(--color-aqua);
  color: var(--color-forest);
}

.variant-hollow {
  color: var(--color-iron);
  border: 1px solid var(--color-cloud);
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.variant-white {
  color: var(--color-black);
  background-color: var(--color-white);

  &.disabled {
    opacity: 0.6;
  }
}

.disabled {
  cursor: not-allowed;
}

.arrow {
  height: 1rem;
  width: 1rem;
  rotate: 180deg;
  align-items: center;
}
