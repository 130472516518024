@import "styles/mixins";

.backdrop {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black-50-percent);
  z-index: 10;

  &.noBackdrop {
    background-color: transparent;
  }
}

.modal {
  border-radius: 0.7rem;
  z-index: 100;

  @include mobile {
    border-radius: 0;
    height: 100% !important;
    width: 100% !important;
  }
}
